
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { BraceletDetail } from '../../types/bracelet'

@Component({
  filters: {
    filtersDeviceStatus (value: string) {
      return value === '1' ? '在线' : value === '2' ? '离线' : ''
    },
    filtersHealthStatus (value: string) {
      return value === '0' ? '正常' : value === '1' ? '异常' : ''
    },
    filtersPresentStatus (value: string) {
      return value === '0' ? '在岗' : value === '1' ? '脱岗' : ''
    },
    filtersSosStatus (value: string) {
      return value === '0' ? '正常' : value === '1' ? '求助' : ''
    },
    filtersPressure (value: string, row: BraceletDetail) {
      const highpressure = row.highpressure || '--'
      const lowpressure = row.lowpressure || '--'
      return highpressure + '/' + lowpressure
    }
  }
})
export default class BraceletDetailList extends Vue {
  private isShowDialog = false
  private tableData = {
    loading: false,
    tr: [
      {
        label: '姓名',
        prop: 'workerName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '关联设备',
        prop: 'relevance',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '健康监测',
        prop: 'healthStatus',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '血压（mmHg）',
        prop: 'highpressure',
        minWidth: '130',
        showOverflowTooltip: true
      },
      {
        label: '心率（次/分钟）',
        prop: 'rate',
        minWidth: '120',
        showOverflowTooltip: true
      },
      {
        label: '在岗监测',
        prop: 'presentStatus',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: 'SOS',
        prop: 'sosStatus',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备状态',
        prop: 'deviceStatus',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备串号',
        prop: 'deviceNumber',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private info = {
    projectId: this.projectId,
    deviceStatus: '', // 设备状态 1在线 2离线
    sosStatus: '', // sos状态 0正常 1求助
    presentStatus: '', // 在岗状态 0 在岗 1 脱岗
    workerName: '', // 工人姓名
    isDevice: '', // 绑定状态 0 未绑定 1已绑定
    deviceNumber: '' // 设备串号
  }

  private workerInfo = {
    workerId: '',
    watchDeviceId: '',
    deviceId: '',
    workerName: ''
  }

  private workerDeviceId = ''

  private workerRules = {
    watchDeviceId: [
      { required: true, message: '请选择手环', trigger: ['blur', 'change'] }
    ]
  }

  private deviceList: {deviceId: string; deviceNumber: string}[] = []

  page = 1
  size = 10
  total = 0

  get projectId () {
    return this.$route.params.projectId as string || ''
  }

  created () {
    this.getData()
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.bracelet.listWorkerBracelet, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  // 获取未绑定的设备
  getDeviceList (callback: any) {
    this.$axios.get(this.$apis.bracelet.listWatchListUnBind, {
      projectId: this.projectId
    }).then(res => {
      this.deviceList = res || []
      callback && callback()
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // sos解除
  cancelSos (id: string) {
    this.$confirm('是否解除SOS报警?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      closeOnClickModal: false
    }).then(() => {
      this.$axios.post(this.$apis.bracelet.cancelSos, {
        workerId: id
      }).then(() => {
        this.$message({
          message: '解除成功',
          type: 'success'
        })
        this.getData()
      })
    })
  }

  // 设备绑定
  bindDevice (row: BraceletDetail) {
    this.getDeviceList(() => {
      if (row.deviceId && row.deviceNumber) {
        // 已绑定的
        this.deviceList.push({
          deviceId: row.deviceId,
          deviceNumber: row.deviceNumber
        })
      }
      this.isShowDialog = true
      this.workerDeviceId = row.deviceId || ''
      this.workerInfo = {
        workerId: row.workerId,
        watchDeviceId: this.workerDeviceId,
        deviceId: row.deviceId ? row.deviceId : '',
        workerName: row.workerName
      }
    })
  }

  bindSubmit () {
    (this.$refs.workerInfo as ElForm).validate((valid) => {
      if (valid) {
        const info = {
          workerId: this.workerInfo.workerId,
          watchDeviceId: this.workerInfo.watchDeviceId
        }
        this.$axios.post(this.$apis.bracelet.bindWatchWorker, info).then(() => {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.closeDialog({ update: true })
        })
      }
    })
  }

  // 手环解绑
  unBindDevice () {
    const info = {
      workerId: this.workerInfo.workerId
    }
    this.$axios.post(this.$apis.bracelet.unBindWatchWorker, info).then(() => {
      this.$message({
        message: '解绑成功',
        type: 'success'
      })
      this.closeDialog({ update: true })
    })
  }

  // 关闭弹框
  closeDialog (bind?: { update: boolean }) {
    this.isShowDialog = false
    this.clearForm()
    if (bind && bind.update) {
      this.getData()
    }
  }

  // 清除表单
  clearForm () {
    this.workerInfo = {
      workerId: '',
      watchDeviceId: '',
      deviceId: '',
      workerName: ''
    }
    this.$nextTick(() => {
      (this.$refs.workerInfo as ElForm).clearValidate()
    })
  }

  // 健康档案
  onRecord (workerId: string) {
    this.$router.push({
      name: 'braceletListHealth',
      params: { projectId: this.projectId, workerId: workerId }
    })
  }

  // 轨迹回放
  onPlayback (id: string) {
    this.$router.push({
      name: 'braceletListPlayback',
      params: { projectId: this.projectId, workerId: id }
    })
  }
}
